import { type AddressProps, days } from '@phx/design-system';
import { PharmacyInfo } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import { getFragment } from '../../graphql';
import {
    type MailOrderProviderInfoFragment,
    type PharmacyProviderInfoFragment,
} from '../../graphql/generated/graphql';
import { usePatientContext } from '../../hooks/use-patient-context';
import { titleCase } from '../../util';
import { mapWeeklyHours } from '../../util/pharmacy/map-weekly-hours';

type PharmacyInfoProps = {
    location: PharmacyProviderInfoFragment | MailOrderProviderInfoFragment;
    distance?: number | null;
    showFavorite?: boolean;
};

export const LocationInfo = ({
    location,
    distance,
    showFavorite = true,
}: PharmacyInfoProps) => {
    const { t } = useTranslation();
    const { preferences } = usePatientContext();
    const { favoritePharmacies } = preferences;
    const { checkIsFavorite, toggleFavorite } = favoritePharmacies;
    let locationInfo;
    const day = days[new Date().getDay()];

    switch (location.__typename) {
        case 'PharmacyProvider': {
            const weeklyHours = location?.weeklyHoursByDayOfWeek
                ? mapWeeklyHours(getFragment(location.weeklyHoursByDayOfWeek))
                : undefined;

            locationInfo = {
                name: titleCase(location?.name),
                address: location?.address as AddressProps['address'],
                phone: location?.phoneNumber,
                hours: weeklyHours?.[day],
                day: t('locationInfo.today'),
                weeklyHours,
                distance: distance
                    ? {
                          distance: distance,
                          units: t('common.distance.distanceUnit'),
                      }
                    : undefined,
                favorite: showFavorite
                    ? checkIsFavorite(location.id)
                    : undefined,
                toggleFavorite: showFavorite
                    ? () => toggleFavorite(location.id)
                    : undefined,
            };
            break;
        }
        case 'MailOrderProvider':
            locationInfo = {
                name: titleCase(location?.name),
                phone: location.phoneNumber,
                shippingTime: '1 - 2',
                url: location?.url ?? undefined,
                favorite: showFavorite
                    ? checkIsFavorite(location.id)
                    : undefined,
                toggleFavorite: showFavorite
                    ? () => toggleFavorite(location.id)
                    : undefined,
            };
            break;
        default:
            locationInfo = {
                name: titleCase(location?.name),
                phone: location?.phoneNumber,
            };
            break;
    }

    return <PharmacyInfo {...locationInfo} />;
};
