import {
    Body1,
    Body2,
    Button,
    Heading1,
    Heading2,
    HorizontalDivider,
    Stack,
    Subtitle2,
} from '@phx/design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getFragment } from '../../graphql';
import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../graphql/generated/graphql';
import { getAbsoluteRoute } from '../../util';
import { isPriceableOffer } from '../../util/offers/is-priceable-offer';
import { ContactUsWidget } from '../common/ContactUsWidget';
import { DrugFamilyHeader } from '../common/DrugFamilyHeader';
import { LocationInfo } from '../common/LocationInfo';
import { NoPricing, Pricing } from '../common/pricing';

export type TransferSubmittedProps = {
    offer: CashOfferInfoFragment | InsuranceOfferInfoFragment;
    rxNumber?: string;
};

/**
 *  Note: It is currently not possible to reliably revisit this page.
 * The transfer process does not generate any type of order that we can query again (just sends a teams message).
 * This is a known issue and eventually should get resolved.
 *
 */

export const TransferSubmitted = ({
    offer,
    rxNumber,
}: TransferSubmittedProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const pharmacyProvider = getFragment(offer.pharmacyProvider);

    let requestedPharmacyDescription = t(
        'transferSubmitted.requestedPharmacy.pickUpDescription'
    );

    if (pharmacyProvider.__typename === 'MailOrderProvider') {
        if (!pharmacyProvider.url) {
            requestedPharmacyDescription = t(
                'transferSubmitted.requestedPharmacy.mailOrderNoWebsite'
            );
        } else {
            requestedPharmacyDescription = t(
                'transferSubmitted.requestedPharmacy.mailOrderWebsite'
            );
        }
    }

    const priceSection = isPriceableOffer(offer) ? (
        <Pricing offer={offer} />
    ) : (
        <NoPricing />
    );

    return (
        <Stack>
            <Heading1>{t('transferSubmitted.title')}</Heading1>
            <Body1>{t('transferSubmitted.subtitle')}</Body1>
            <Subtitle2>{t('transferSubmitted.description')}</Subtitle2>
            <Heading2>
                {t('transferSubmitted.subheader.requestedPharmacy')}
            </Heading2>
            <Body1>{requestedPharmacyDescription}</Body1>
            <LocationInfo location={pharmacyProvider} />
            <Heading2>
                {t('transferSubmitted.subheader.yourPrescription')}
            </Heading2>
            <DrugFamilyHeader drugId={offer.product.id} />
            {rxNumber ? (
                <Body1>
                    {t('transferSubmitted.prescriptionNumber')}
                    {rxNumber}
                </Body1>
            ) : null}
            <Stack gap="xxs">
                {priceSection}
                <Body2>{t('pricing.pricingInfo.confirmPricing')}</Body2>
            </Stack>
            <HorizontalDivider />
            <ContactUsWidget />
            <Button
                size="lg"
                onClick={() => navigate(getAbsoluteRoute('home'))}
            >
                {t('transferSubmitted.button')}
            </Button>
        </Stack>
    );
};
