import { FilledCard, Heading2, Stack } from '@phx/design-system';
import { PricingInfo } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../../graphql/generated/graphql';
import { formatToLocalizedCurrency } from '../../../util';

export const MailOrderPricing = ({
    offer,
}: {
    offer: CashOfferInfoFragment | InsuranceOfferInfoFragment;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm" data-testid="price-quote-card">
            <Heading2>{t('pricing.subHeader')}</Heading2>
            <PricingInfo
                option={offer.pricingDetail.option}
                value={offer.pricingDetail.value}
                details={offer.pricingDetail.details ?? ''}
            />
            <PricingInfo
                option={t('pricing.pricingInfo.deliveryFee')}
                value={`${formatToLocalizedCurrency(0)}`}
                details={t('pricing.pricingInfo.details')}
            />
            <FilledCard color="gray">
                <PricingInfo
                    option={t('pricing.pricingInfo.youPay')}
                    value={offer.pricingDetail.value}
                />
            </FilledCard>
        </Stack>
    );
};
