import { Accordion, Body1, Heading1, Stack } from '@phx/design-system';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useFaqTranslationRoutes } from './FaqTranslationRoutes';

export type FaqProps = { section: string };

const Faq = ({ section }: FaqProps) => {
    const { t } = useTranslation(['help-center-locales/help-center']);
    const routes = useFaqTranslationRoutes();

    const location = useLocation();
    const header = location.state?.header;

    const faqs = useMemo(() => {
        const faqObjects = t(`faq.${section}`, { returnObjects: true });

        if (!Array.isArray(faqObjects)) {
            return [];
        }

        return faqObjects.map((faq) => ({
            header: faq.question,
            key: faq.question,
            children: (
                <Stack>
                    <Body1>
                        <Trans components={routes}>{faq.answer}</Trans>
                    </Body1>
                    {faq.answer2 ? <Body1> {faq.answer2}</Body1> : null}
                </Stack>
            ),
        }));
    }, [t, section]);

    return (
        <Stack>
            <Heading1>{header}</Heading1>
            <Accordion items={faqs} />
        </Stack>
    );
};

export default Faq;
